<template>
  <div>
    <navbar></navbar>
    <b-button
      size="sm"
      variant="danger"
      to="/audit/new"
      class="float-right mauve"
    >
      <font-awesome-icon icon="plus-circle" />
      Nouveau PDF
    </b-button>
    <div class="table-responsive">
      <div v-if="getPdfLoading" class="loading ml-2">
        <div class="spinner-border" role="status"></div>
      </div>
      <table class="content-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Demandeur</th>
            <th>Codemandeur</th>
            <th>Adresse</th>
            <th>Ville</th>
            <th>Télécharger</th>
            <th>Modifier</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pdf, i) in getAllPdf" :key="i" @click="handleUpdate(pdf)">
            <td>{{ pdf.id }}</td>
            <td>{{ pdf.nomPrenomDemandeur }}</td>
            <td>{{ pdf.nomPrenomCodemandeur }}</td>
            <td>{{ pdf.adresse }}</td>
            <td>{{ pdf.ville }}</td>
            <td>
              <font-awesome-icon
                icon="download"
                @click.prevent.stop="handleDownload(pdf.id)"
              />
            </td>
            <td class="icon">
              <span
                ><font-awesome-icon
                  icon="pencil-alt"
                  @click.prevent.stop="handleUpdate(pdf)"
              /></span>
              <span
                ><font-awesome-icon
                  icon="trash"
                  @click.prevent.stop="handleDelete(pdf)"
                  style="color: red"
              /></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal id="ModelConfig" ref="ModelConfig" :hide-footer="true">
      <template #modal-header="{ close }">
        <h5>Modifier Utilisateur</h5>
        <b-button size="sm" @click="close('ModelConfig')">
          <font-awesome-icon icon="times" style="font-size: 30px" />
        </b-button>
      </template>

      <form
        @submit.prevent="pdfModalNew"
        v-if="pdfToConfig"
        class="form-modal-custom-style"
      >
        <div class="flexW">
          <b-form-group label="Demandeur" class="input-modal-champ">
            <b-form-input
              id="name"
              v-model="pdfToConfig.nomPrenomDemandeur"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Codemandeur" class="input-modal-champ">
            <b-form-input
              id="name"
              v-model="pdfToConfig.nomPrenomCodemandeur"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="adresse" class="input-modal-champ">
            <b-form-input
              id="name"
              v-model="pdfToConfig.adresse"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="ville" class="input-modal-champ">
            <b-form-input id="name" v-model="pdfToConfig.ville"></b-form-input>
          </b-form-group>

          <b-form-group label="codePostal" class="input-modal-champ">
            <b-form-input
              id="name"
              v-model="pdfToConfig.codePostal"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="NbrPersonneFoyer" class="input-modal-champ">
            <b-form-input
              id="name"
              v-model="pdfToConfig.NbrPersonneFoyer"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="RevenueFiscaleRef" class="input-modal-champ">
            <b-form-input
              id="name"
              v-model="pdfToConfig.RevenueFiscaleRef"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="actionModel">
          <div class="messageError"></div>
          <b-button
            variant="danger"
            type="submit"
            class="succes-btn-modal btn-danger mt-4"
          >
            <div class="block-spinner">
              <div v-if="getPdfLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
              <div v-else>Modifier</div>
            </div>
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="ModelDelete" ref="ModelDelete" :hide-footer="true">
      <template #modal-header="{ close }">
        <h5>Supprimer Utilisateur</h5>
        <b-button size="sm" @click="close('ModelDelete')">
          <font-awesome-icon icon="times" style="font-size: 30px" />
        </b-button>
      </template>

      <form
        @submit.prevent="pdfDelete"
        v-if="pdfToDelete"
        class="form-modal-custom-style"
      >
        <h2 class="main-color text-center">Supprimer ?</h2>
        <div class="mb-4 text-center red">
          <font-awesome-icon icon="trash" style="font-size: 30px" />
        </div>

        <div class="actionModel">
          <div class="messageError"></div>
          <b-button
            variant="danger"
            type="submit"
            class="succes-btn-modal btn-danger mauve"
          >
            <div class="block-spinner">
              <div v-if="getPdfLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
              <div v-else>Supprimer</div>
            </div>
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import navbar from '@/components/navbar.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    navbar,
  },
  data() {
    return {
      pdfToConfig: null,
      pdfToDelete: null,
      pdfToDownload: null,
    }
  },

  methods: {
    ...mapActions(['delete_pdf', 'all_pdf', 'edit_pdf', 'download_pdf']),

    close(ref) {
      this.$refs[ref].hide()
    },

    handleUpdate(CAT) {
      this.pdfToConfig = { ...CAT }
      this.$refs['ModelConfig'].show()
    },

    handleDelete(CAT) {
      this.pdfToDelete = { ...CAT }
      this.$refs['ModelDelete'].show()
    },

    async pdfModalNew() {
      await this.edit_pdf(this.pdfToConfig).then(() => {
        this.close('ModelConfig')
      })
    },

    async pdfDelete() {
      await this.delete_pdf(this.pdfToDelete).then(() => {
        this.close('ModelDelete')
      })
    },

    async handleDownload(id) {
      await this.download_pdf(id)
    },
  },

  computed: {
    ...mapGetters(['getPdfLoading', 'getAllPdf']),
  },
  mounted() {
    this.all_pdf()
  },
}
</script>

<style lang="scss">
.content-table {
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  width: 95%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  thead tr {
    background-color: #6f42c1;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
  }

  th,
  td {
    padding: 12px 15px;
  }
}

.table-responsive {
  @media only screen and (max-width: 900px) {
    padding: 0 15px;
  }
  min-width: 100%;
  margin: 25px auto;
}

.content-table tbody tr {
  cursor: pointer;
  border-bottom: 1px solid #dddddd;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f3f3f3;
    color: #6f42c1;
  }

  &:last-of-type {
    border-bottom: 1px solid #6f42c1;
  }

  .icon {
    @media only screen and (max-width: 900px) {
      padding: 24px 0;
    }
    display: flex;
    justify-content: space-evenly;
  }
}

.cutsom-modal-bootstrap {
  background-color: #a8a8b81f;
}

.modal-header {
  background-color: #6f42c1;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  & h5 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    position: relative;
  }

  & h5::after {
    content: '';
    width: 59px;
    border-top: 3px solid red;
    position: absolute;
    left: 0;
    bottom: -4px;
  }

  & button {
    background-color: transparent;
    border: none;
  }
}

.modal-body {
  background-color: #fafdff;
  padding: 14px;

  form {
    margin: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 14px 14px 8px;
    box-shadow: 1px 1px 24px #00000019;

    input,
    select {
      height: 42px;
    }

    select {
      width: 100%;
    }
  }

  .actionModel {
    display: flex;
    justify-content: center;
    & button {
      width: 363px;
    }
    & .block-spinner {
      display: flex;
      justify-content: center;
    }

    & .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.float-right {
  @media only screen and (max-width: 900px) {
    margin-right: 4px;
  }
  float: right;
  margin: 15px 32px 15px 0;
}
.mauve {
  box-shadow: none;
  background-color: #6f42c1;
  border-color: #6f42c1;
}

.flexW {
  display: flex;
  flex-wrap: wrap;
}

#ModelConfig {
  & .modal-md {
    @media only screen and (max-width: 900px) {
      max-width: 100%;
    }
    max-width: 75%;
  }

  & fieldset {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 50%;
  }
}
</style>
